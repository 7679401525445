/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react';
import { SlimBanner } from 'components';
import { Text, Icon } from 'elements';
import ThumbsUp from 'elements/icon/assets/thumbs-up.svg';
import { MessageWrapper } from './SeedlingLP.styled';
import SeedlingsTitle from './components/seedlings-title/SeedlingsTitle';

const sections = {
  SEEDLINGS: '698CVZDrDMhL572avhBB67',
  FREE_SHIPPING_PROMO: '7jApYo3wClzS0lXhOnxiFj',
};

export default {
  [sections.SEEDLINGS]: ({ id, title, body }) => <SeedlingsTitle key={id} title={title} body={body} />,
  [sections.FREE_SHIPPING_PROMO]: ({ id, body }) =>
    body && (
      <MessageWrapper key={id}>
        <SlimBanner modifiers='lightGreen2'>
          <Icon modifiers={['brandColor', 'medium']}>
            <ThumbsUp />
          </Icon>
          <Text modifiers={['brandFont', 'left', 'brandColor']} content={body} isHTML />
        </SlimBanner>
      </MessageWrapper>
    ),
};
