import styled from 'styled-components';

import breakpoints from 'theme/Media';

export const RecipesInner = styled.div.attrs({ className: 'RecipesInner' })`
  display: grid;
  grid-template-columns: 1;
  grid-gap: 7rem 2rem;
  margin: 0 auto 3rem;
  width: 80%;

  .gatsby-image-wrapper {
    min-width: 100%;
  }
  @media ${breakpoints.medium_break} {
    grid-template-columns: repeat(2, minmax(15rem, 1fr));
  }
  @media ${breakpoints.large_break} {
    grid-template-columns: repeat(3, minmax(15rem, 1fr));
  }
`;
