/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';

import { Image, Text, Title } from 'elements';
import { HeroSection } from 'components';

import { AssemblyHeroStyled, HeroTextWrapper } from './AssemblyHero.styled';

const AssemblyHero = ({ heroTitle, heroText, heroImage, heroImageAlt }) => {
  return (
    <AssemblyHeroStyled>
      <HeroSection>
        <HeroTextWrapper>
          <Title as='h2' modifiers={['letterSpaceSmall']} content={heroTitle} />
          <Text modifiers='left' content={heroText} />
        </HeroTextWrapper>
        <Image picture={heroImage} alt={heroImageAlt} />
      </HeroSection>
    </AssemblyHeroStyled>
  );
};

AssemblyHero.propTypes = {
  heroTitle: PropTypes.string,
  heroText: PropTypes.string,
  heroImage: PropTypes.string,
  heroImageAlt: PropTypes.string,
};

export default AssemblyHero;
