import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

import { ContentSection } from 'components';
import { SquaredButton, Title, Text, GatsbyImageLink } from 'elements';

import { ContentWrapper } from 'staticPages/home/Home.styled';
import { FarmStandTableSection } from './FarmTableSection.styled';

const FarmTableSection = ({ title, body, callToAction }) => {
  const imageData = graphql`
    query {
      contentfulSectionStandard(contentful_id: { eq: "4I3ls92Q9zAxvRm25LWu62" }) {
        mediaContent {
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 70)
        }
      }
    }
  `;
  const ctaLabel = callToAction?.fields?.linkText || callToAction?.fields?.text;
  const ctaLink = callToAction?.fields?.linkUrl || callToAction?.fields?.url;

  return (
    <StaticQuery
      query={imageData}
      render={(data) => {
        return (
          <FarmStandTableSection>
            <ContentSection modifiers={['leftText']} animation='crossFade'>
              <GatsbyImageLink
                modifiers='relativePosition'
                image={data?.contentfulSectionStandard?.mediaContent?.gatsbyImageData}
                alt={data?.contentfulSectionStandard?.mediaContent?.description}
                imageRouteLink={callToAction?.fields?.linkUrl}
                eventData={{ action: `IMAGE - ${ctaLabel}` }}
              />
              <ContentWrapper>
                <Title content={title} modifiers='secondarySmall' as='h3' />
                <Text content={body} />
                <SquaredButton as='a' label={ctaLabel} href={ctaLink} eventData={{ action: ctaLabel }} />
              </ContentWrapper>
            </ContentSection>
          </FarmStandTableSection>
        );
      }}
    />
  );
};

FarmTableSection.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  callToAction: PropTypes.object,
};

export default FarmTableSection;
