import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';

import { ContentSection } from 'components';
import { SquaredButton, Text, Title, GatsbyImageLink } from 'elements';

import { ContentWrapper } from 'staticPages/home/Home.styled';
import { ZooeyHarvestPlan } from './HarvestSection.styled';

const HarvestSection = ({ title, body, callToAction }) => {
  const imageData = graphql`
    query {
      contentfulSectionStandard(contentful_id: { eq: "5qH9RCO07TqZeaGfMXdYbx" }) {
        mediaContent {
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 70)
        }
      }
    }
  `;

  return (
    <StaticQuery
      query={imageData}
      render={(data) => {
        return (
          <ZooeyHarvestPlan>
            <ContentSection modifiers={['leftText']} animation='crossFade'>
              <GatsbyImageLink
                modifiers='relativePosition'
                image={data?.contentfulSectionStandard?.mediaContent?.gatsbyImageData}
                alt={data?.contentfulSectionStandard?.mediaContent?.description}
                imageRouteLink={callToAction?.fields?.linkUrl}
              />
              <ContentWrapper>
                <Title content={title} modifiers='secondarySmall' as='h3' />
                <Text content={body} isHTML />
                <SquaredButton
                  as='a'
                  label={callToAction?.fields?.linkText || callToAction?.fields?.text}
                  href={callToAction?.fields?.linkUrl || callToAction?.fields?.url}
                />
              </ContentWrapper>
            </ContentSection>
          </ZooeyHarvestPlan>
        );
      }}
    />
  );
};

HarvestSection.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  callToAction: PropTypes.object,
};

export default HarvestSection;
