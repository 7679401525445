import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const AssemblyHeroStyled = styled.div.attrs({
  className: 'AssemblyHeroStyled',
})`
  background-color: ${({ theme }) => theme.farmProject.gray_4};

  .HeroSection {
    justify-content: center;
    padding: 0;
    height: auto;
    max-height: unset;
    max-width: 62rem;
    margin: auto;
    background-color: ${({ theme }) => theme.farmProject.gray_4};

    .HeroInner {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column-reverse;

      .Image {
        position: relative;
        top: auto;
        left: auto;
        transform: none;

        width: 100%;
        height: 30rem;

        picture {
          img {
            transform: translate(-60%, -50%);
          }
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .HeroSection {
      .HeroInner {
        .HeroTextWrapper {
          max-width: 24rem;
        }

        .Image {
          width: 100%;
          height: 30rem;

          picture {
            img {
              width: auto;
              height: 100%;
              transform: translate(-55%, -50%);
            }
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .HeroSection {
      height: auto;

      .HeroInner {
        flex-direction: row;
        justify-content: flex-end;

        .HeroTextWrapper {
          padding: 0;
          width: 100%;
          flex-shrink: 0;
          max-width: 30rem;

          .Title {
            font-size: 4rem;
            letter-spacing: normal;
            text-align: left;
          }

          .Text {
            text-align: left;
          }
        }

        .Image {
          flex-shrink: 0;
          height: 36.125rem;

          picture {
            img {
              height: 100%;
              width: auto;
            }
          }
        }
      }
    }
  }
`;

export const HeroTextWrapper = styled.div.attrs({
  className: 'HeroTextWrapper',
})`
  padding: 2rem;
  position: absolute;
  left: 0;
  max-width: 13rem;
  z-index: 6;

  .Title {
    width: 100%;
    padding: 0;
    font-size: 2rem;
    margin-bottom: 0.25rem;
  }

  @media ${breakPoints.medium_break} {
    .Title {
      font-size: 3rem;
    }
  }
`;
