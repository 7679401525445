import React from 'react';
import PropTypes from 'prop-types';

import { Text, Title, Image, Link } from 'elements';

import { BlogCardStyled, ImageWrapper, InfoWrapper } from './BlogCard.styled';
import paths from 'constants/paths';

const BlogCard = ({ slug, catSlug, postTitle, postText, picture, alt, postCategory }) => {
  return (
    <BlogCardStyled>
      <Link href={`${paths.BLOG}/${slug}`}>
        <ImageWrapper>
          <Image modifier={['cover']} picture={picture} alt={alt} />
        </ImageWrapper>
      </Link>
      <InfoWrapper>
        <Link href={`${paths.BLOG}/${catSlug}`}>
          <Text modifiers={['brandFont', 'small']}>{postCategory}</Text>
        </Link>
        <Link href={`${paths.BLOG}/${slug}`}>
          <Title as='h4' modifiers={['tertiaryXSmall', 'letterSpaceSmall']} content={postTitle} />
          <Text>{postText}</Text>
        </Link>
      </InfoWrapper>
    </BlogCardStyled>
  );
};

BlogCard.propTypes = {
  slug: PropTypes.string,
  catSlug: PropTypes.string,
  postTitle: PropTypes.string,
  postCategory: PropTypes.string,
  postText: PropTypes.string,
  picture: PropTypes.string,
  alt: PropTypes.string,
};

export default BlogCard;
