/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';

import { Image, Title } from 'elements';
import { HeroSection } from 'components';

import { ReturnHeroStyled, HeroTextWrapper } from './ReturnHero.styled';

const ReturnHero = ({ heroTitle, heroImage, heroImageAlt }) => {
  return (
    <>
      <ReturnHeroStyled>
        <HeroSection>
          <HeroTextWrapper>
            <Title as='h2' modifiers={['letterSpaceSmall']} content={heroTitle} />
          </HeroTextWrapper>
          <Image picture={heroImage} alt={heroImageAlt} />
        </HeroSection>
      </ReturnHeroStyled>
    </>
  );
};

ReturnHero.propTypes = {
  heroTitle: PropTypes.string,
  heroText: PropTypes.string,
  heroImage: PropTypes.string,
  heroImageAlt: PropTypes.string,
};

export default ReturnHero;
