import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const AssemblyCardStyled = styled.div.attrs({
  className: 'AssemblyCardStyled',
})`
  border-radius: 0.25rem;
  width: 100%;
  min-height: 5.25rem;
  box-sizing: border-box;
  padding: 2rem;
  position: relative;
  margin: 5rem 0;

  @media ${breakPoints.large_break} {
    padding: 5rem;
    margin: 8rem 0;
    background-color: ${({ theme }) => theme.farmProject.gray_4};
  }
`;

export const AssemblyInfo = styled.div.attrs({
  className: 'AssemblyInfo',
})`
  margin-bottom: 0.5rem;

  .Title {
    text-align: left;
    margin-bottom: 0.25rem;
    max-width: 16rem;
  }

  .Text {
    text-align: left;
    max-width: 21rem;
  }

  @media ${breakPoints.large_break} {
    .Title {
      font-size: 2.125rem;
      max-width: 22rem;
    }

    .Text {
      font-size: 1.125rem;
      max-width: 30rem;
    }
  }
`;

export const AssemblyImageWrapper = styled.div.attrs({
  className: 'AssemblyImageWrapper',
})`
  height: 23rem;
  width: 10rem;
  position: absolute;
  transform: translate(-50%, -50%);
  right: -9rem;
  top: 50%;

  @media ${breakPoints.large_break} {
    height: 35rem;
    width: 15rem;
    right: -4rem;
  }
`;

export const ButtonGroup = styled.div.attrs({
  className: 'ButtonGroup',
})`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1.5rem;

  .Button {
    width: 100%;
    position: relative;
  }

  .Button + .Button {
    .Icon {
      position: absolute;
      width: 0.75rem;
      transform: translate(-4rem);
      pointer-events: none;
    }
  }

  @media ${breakPoints.large_break} {
    max-width: 17rem;
  }
`;
