import React from 'react';
import PropTypes from 'prop-types';

import { ContentSection } from 'components';

import { AssemblyStepsStyled, StepList } from './AssemblySteps.styled';
import AssemblyCard from '../assembly-card/AssemblyCard';

const AssemblySteps = ({ stepsData }) => {
  const renderStep = (item) => {
    const { title, body, mediaContent, additionalButtons } = item.fields;
    return (
      <AssemblyCard
        key={item?.sys?.id}
        assemblyTitle={title}
        assemblyText={body}
        picture={mediaContent?.fields?.file?.url}
        alt={mediaContent?.fields?.description}
        ctas={additionalButtons}
      />
    );
  };

  return (
    <AssemblyStepsStyled>
      <StepList>
        <ContentSection>{stepsData?.map?.(renderStep)}</ContentSection>
      </StepList>
    </AssemblyStepsStyled>
  );
};

AssemblySteps.propTypes = {
  stepsData: PropTypes.node,
};

export default AssemblySteps;
