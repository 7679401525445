/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React from 'react';

import { PressReviewSlider, Featured } from 'components';
import HowItsMade from './components/how-its-made/HowItsMade';
import FarmGrow from './components/farm-grow/FarmGrow';
import FarmHero from './components/farm-hero/FarmHero';

export const sections = {
  FARMSTAND: '7uMvpG9mQmUuq1K6yxyBSS',
  BRANDS: 'ImssOjXCN4kLN3lnPuxxP',
  HOW_ITS_MADE: '6XxdTTy6fo7uVgrh4z2ZG6',
  GROW: '1BLGRAfaUyE5g2AB6he2fs',
  FAQ: '3vbbqGgVf3d6tNwrKlGv27',
};

const pageSpecificMap = {
  [sections.FARMSTAND]: ({ title, body, lowerTitle, percentages }, farmSizeFromUrl) => (
    <FarmHero
      key={sections.FARMSTAND}
      title={title}
      body={body}
      lowerTitle={lowerTitle}
      percentages={percentages}
      farmSize={farmSizeFromUrl}
    />
  ),
  [sections.BRANDS]: ({ body, percentages }) => (
    <PressReviewSlider body={body} brands={percentages} key={sections.BRANDS} showCallout modifiers='backgroundGreen' />
  ),
  [sections.HOW_ITS_MADE]: ({ title, percentages }) => <HowItsMade key={sections.HOW_ITS_MADE} title={title} percentages={percentages} />,
  [sections.GROW]: ({ name, title, body, callToAction }) => (
    <FarmGrow key={sections.GROW} name={name} title={title} body={body} callToAction={callToAction} />
  ),
  [sections.FAQ]: ({ title, percentages, callToAction }) => (
    <Featured key={sections.FAQ} title={title} percentages={percentages} callToAction={callToAction} modifiers='white' />
  ),
};

export default pageSpecificMap;
