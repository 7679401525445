import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Text, Title, Image, SquaredButton, Icon } from 'elements';

import { AssemblyCardStyled, AssemblyInfo, AssemblyImageWrapper, ButtonGroup } from './AssemblyCard.styled';

import { VideoModal } from 'components/modal';
import PlayButton from 'elements/icon/assets/play-filled-icon.svg';

const Assemblycard = ({ assemblyTitle, assemblyText, picture, alt, ctas = [] }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [instructionCTA, videoCTA] = ctas.map(({ fields }) => fields);

  const onOpenModal = () => setIsModalOpen(true);

  const onCloseModal = () => setIsModalOpen(false);

  const getVideoIframe = (videoUri) => {
    return `
      <iframe
        width='560'
        height='315'
        src='${videoUri}'
        title='LG player'
        frameborder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowfullscreen
      />
    `;
  };

  return (
    <AssemblyCardStyled>
      <AssemblyInfo>
        <Title as='h4' modifiers={['xSmall', 'letterSpaceSmall', 'semiBold']} content={assemblyTitle} />
        <Text modifiers={['brandFont', 'xLarge']}>{assemblyText}</Text>
        <ButtonGroup>
          {!!instructionCTA?.linkUrl && (
            <SquaredButton
              modifiers={['tertiary']}
              label={instructionCTA.linkText}
              href={instructionCTA.linkUrl}
              target={instructionCTA.target}
              as='a'
            />
          )}
          {!!videoCTA?.linkUrl && (
            <SquaredButton modifiers={['lined']} label={videoCTA.linkText} onClick={onOpenModal}>
              <Icon>
                <PlayButton />
              </Icon>
            </SquaredButton>
          )}
        </ButtonGroup>
      </AssemblyInfo>
      <AssemblyImageWrapper>
        <Image picture={picture} alt={alt} />
      </AssemblyImageWrapper>
      {!!videoCTA?.linkUrl && <VideoModal show={isModalOpen} embedCode={getVideoIframe(videoCTA.linkUrl)} onCloseAction={onCloseModal} />}
    </AssemblyCardStyled>
  );
};

Assemblycard.propTypes = {
  assemblyTitle: PropTypes.string,
  assemblyText: PropTypes.string,
  picture: PropTypes.string,
  alt: PropTypes.string,
  ctas: PropTypes.array,
};

export default Assemblycard;
