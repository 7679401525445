import styled from 'styled-components';

export const SeedlingsSearchStyled = styled.div.attrs({
  className: 'SeedlingsSearchStyled',
})`
  width: 100%;
  box-sizing: border-box;
  max-width: 25rem;
  margin: 2rem auto 1.5rem;
  position: relative;

  .Icon {
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top: calc(50% - -0.5rem);
    left: auto;
    right: 0;
    pointer-events: none;
    transform: translate(-50%, -50%) rotateY(180deg);

    svg {
      width: 95%;
      height: 95%;
    }
  }

  .MuiAutocomplete-root {
    .MuiFormControl-root {
      .MuiAutocomplete-inputRoot {
        padding-right: 0;
      }
      .MuiFormLabel-root {
        font-size: 0.875rem;
        font-family: ${({ theme }) => theme.farmProject.font_family};
        top: -5px;
      }
      .MuiInputLabel-shrink {
        color: ${({ theme }) => theme.farmProject.green};
      }

      .MuiInputBase-root {
        .MuiInputBase-input {
          height: 2.5rem;
          font-size: 1.25rem;
          position: relative;
          top: 0.4rem;
        }
        .MuiAutocomplete-endAdornment {
          /* top: 1rem; */
          right: 2.5rem;
        }
      }
    }
    .MuiChip-deletable {
      border-radius: 0;
      background-color: ${({ theme }) => theme.farmProject.gray_2};
      position: relative;

      .MuiChip-label {
        font-family: ${({ theme }) => theme.farmProject.font_family};
        font-size: 0.75rem;
        color: ${({ theme }) => theme.farmProject.dark_gray};
        padding-left: 2rem;
      }

      &.Mui-focused {
        color: ${({ theme }) => theme.farmProject.brand_primary};
      }

      .MuiAutocomplete-endAdornment {
        top: -0.125rem;
      }
    }

    .MuiFilledInput-underline:before {
      border-bottom-color: ${({ theme }) => theme.farmProject.dark_gray};
    }

    .MuiFilledInput-underline:after {
      border-bottom-color: ${({ theme }) => theme.farmProject.brand_primary};
    }

    .MuiInput-underline:before {
      border-bottom-color: ${({ theme }) => theme.farmProject.dark_gray};
    }

    .MuiInput-underline:after {
      border-bottom-color: ${({ theme }) => theme.farmProject.brand_primary};
    }
  }
`;
