import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const PageTopInfo = styled.section.attrs({
    className: 'PageTopInfo',
  })`
    .Title {
      margin-bottom: 1.5rem;
    }

    .Text {
      max-width: 55rem;
      margin: auto;
    }

    @media ${breakPoints.large_break} {
      .Title {
        font-size: 4rem;
        max-width: 40rem;
        margin: 0 auto 2rem;
      }
    }
  `;