import styled from 'styled-components/macro';

import breakPoints from 'theme/Media';

export const PreSproutedSection = styled.div.attrs({
  className: 'PreSproutedSection',
})`
  background-color: ${({ theme }) => theme.farmProject.gray_3};

  .ContentSection {
    .SectionInner {
      .Link {
        position: relative;

        .Image {
          margin-bottom: 2rem;
        }
      }
      .ContentWrapper {
        .Title {
          margin-bottom: 1rem;
        }

        .Button {
          width: 100%;
          max-width: 24rem;
          margin: auto;
          margin-top: 1.5rem;
          margin-left: 0;
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      padding: 5rem 3rem;

      .SectionInner {
        .Link {
          .Image {
            margin-bottom: 4rem;
          }
        }

        .ContentWrapper {
          .Title {
            font-size: 3rem;
            max-width: 20rem;
            margin-bottom: 1.5rem;
          }

          .Text {
            max-width: 28rem;
            margin-left: 0;
          }

          .Button {
            margin-top: 3rem;
            max-width: 17rem;
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      .SectionInner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;

        .Link {
          .Image {
            margin-bottom: 0;
          }
        }

        > a {
          width: calc(50% - 2rem);
        }

        .Image {
          margin-left: 0;
          margin-bottom: 0;
        }

        .ContentWrapper {
          width: calc(50% - 2rem);

          .Title {
            text-align: left;
          }

          .Text {
            text-align: left;
            margin-left: 0;
          }

          .Button {
            margin-left: 0;
          }
        }
      }
    }
  }
`;
