import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';

import { FarmstandPDPSection } from 'components';

const FARMSTAND_HERO_SECTIONS = {
  FARM_SIZE: '5JQf3dg2LtvPvmT4uOQ2Do',
  FARMSTANDS: '1368lss8SXKqO26v9USf0B',
  GLOW_RINGS: '1SclKZRK8roIqXV4FWna7p',
  INFO: 'qOWbaMH174wZGXcvkk9Bt',
  ACCORDION: '6bsc3tNE7u9chJ17SNlRcm',
  GIFT: '70NeXaQmuLKX5lWC28mlfB',
  PROMO: '6VUrIn7ScLkCHmoiBHqLNM',
  VISUALIZER_BANNER: 'xVLZyh7vzRqUNtl0OIP86',
};

const FarmHero = ({ title, body, lowerTitle, percentages, farmSize }) => {
  const imageData = graphql`
    query {
      contentfulSectionBlocks(contentful_id: { eq: "7uMvpG9mQmUuq1K6yxyBSS" }) {
        mediaBackground {
          title
          contentful_id
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 70)
          title
          file {
            url
            contentType
            fileName
          }
        }
        leftBackgroundAccent {
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 70)
        }
        body2 {
          body2
          id
        }
      }
    }
  `;

  return (
    <StaticQuery
      query={imageData}
      render={(data) => (
        <FarmstandPDPSection
          title={title}
          body={body}
          lowerTitle={lowerTitle}
          percentages={percentages}
          farmSize={farmSize}
          customImageData={data}
          customSectionIDs={FARMSTAND_HERO_SECTIONS}
        />
      )}
    />
  );
};

FarmHero.propTypes = {
  title: PropTypes.string,
  lowerTitle: PropTypes.string,
  body: PropTypes.string,
  percentages: PropTypes.array,
  farmSize: PropTypes.string,
};

export default FarmHero;
