import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const BlogCardStyled = styled.div.attrs({
  className: 'BlogCardStyled',
})`
  width: 100%;
  box-sizing: border-box;
  transition: all ease-in-out 0.4s;

  &:hover {
    background-color: ${({ theme }) => theme.farmProject.gray_1};
    .Link {
      .Title,
      .Text {
        color: ${({ theme }) => theme.farmProject.dark_gray};
      }
    }
  }

  .Link {
    .Title,
    .Text {
      text-decoration: none;
    }
  }
`;

export const ImageWrapper = styled.div.attrs({
  className: 'ImageWrapper',
})`
  height: 9.8rem;
`;

export const InfoWrapper = styled.div.attrs({
  className: 'InfoWrapper',
})`
  padding: 1.25rem 1.5rem 1.5rem;

  .Text {
    font-size: 0.75rem;
    margin-bottom: 1rem;
    letter-spacing: 0.16rem;

    &:last-of-type {
      font-size: 0.875rem;
      margin-bottom: 0;
      letter-spacing: 0.05rem;
      line-height: 1.6;
      margin-top: 0.5rem;
    }
  }

  @media ${breakPoints.large_break} {
  }
`;
