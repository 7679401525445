import styled from 'styled-components';
import breakPoints from 'theme/Media';

import { applyStyleModifiers } from 'styled-components-modifiers';

export const modifiers = {
  selected: ({ theme }) => ({
    styles: `
      border-color: ${theme.farmProject.green};
      .Icon {
        opacity: 1;
        svg {
          path {
            fill: ${theme.farmProject.green};
          }
        }
      }
      .Text {
        color: ${theme.farmProject.green};
      }
    `,
  }),
};

export const ReturnInfoStyled = styled.div.attrs({
  className: 'ReturnInfoStyled',
})`
  .ContentSection {
    background-color: white;
    .SectionInner {
      max-width: 33rem;
      margin: auto;
    }

    .SectionInner {
      > .Title {
        margin-bottom: 0.25rem;
      }

      .Image {
        height: 20rem;
        position: absolute;
        top: auto;
        left: 50%;
        bottom: -14.5rem;
        transform: translate(-50%, -50%);
        max-width: 25rem;
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      .SectionInner {
        max-width: 62rem;
        display: flex;

        > .Title {
          font-size: 3rem;
        }

        > .Text {
          max-width: 35rem;
          margin: auto;
        }

        .Image {
          max-width: 40rem;
          height: 23rem;
        }
      }
    }
  }
`;

export const CallToActionWrapper = styled.div.attrs({
  className: 'CallToActionWrapper',
})`
  margin-top: 3.75rem;
`;

export const ButtonGroup = styled.div.attrs({
  className: 'ButtonGroup',
})`
  margin-top: 0.25rem;

  .Button {
    width: 100%;
    margin: 1rem 0;
    position: relative;

    .Icon {
      position: absolute;
      width: 0.75rem;
      transform: translate(-4rem);
      pointer-events: none;
    }
  }

  > .Text {
    display: none;
  }

  @media ${breakPoints.medium_break} {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .Button {
      max-width: 17rem;
    }

    > .Text {
      display: block;
      margin: 0 1.5rem;
    }
  }
`;

export const InfoTextWrapper = styled.div.attrs({
  className: 'InfoTextWrapper',
})`
  .Title {
    margin-bottom: 1rem;
  }
`;

export const InfoInner = styled.div.attrs({
  className: 'InfoInner',
})``;

export const CategoryWrapper = styled.div.attrs({
  className: 'CategoryWrapper',
})`
  > .Title {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  @media ${breakPoints.large_break} {
    width: 21rem;
    position: relative;
    top: -1.5rem;
  }
`;

export const CategoryButtons = styled.div.attrs({
  className: 'CategoryButtons',
})`
  margin-bottom: 1.5rem;
  margin-left: -2rem;
  padding: 0 2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  scrollbar-width: none;

  @media ${breakPoints.large_break} {
    flex-direction: column;
    width: 16rem;
    background-color: ${({ theme }) => theme.farmProject.gray_5};
    padding: 0 1.5rem;
    margin-right: 2.5rem;
    margin-left: 0;
    border-radius: 0.25rem;
    overflow-x: unset;

    .CategoryButton {
      &:last-of-type {
        border-bottom: transparent;
      }
    }
  }
`;

export const CategoryButton = styled.button.attrs({
  className: 'CategoryButton',
})`
  width: 7.25rem;
  height: 7.25rem;
  border: solid 1px ${({ theme }) => theme.farmProject.dark_gray};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  flex-direction: column;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
  flex-shrink: 0;
  cursor: pointer;
  position: relative;

  > .Icon {
    display: none;
  }

  .Text {
    font-size: 0.875rem;
  }

  @media ${breakPoints.large_break} {
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    height: 6rem;
    border: solid 1px transparent;
    border-bottom: solid 1px ${({ theme }) => theme.farmProject.gray_1};

    .Icon {
      margin-right: 1rem;
      display: block;
      margin-bottom: 0;
    }

    > .Icon {
      position: absolute;
      width: 1rem;
      height: 1rem;
      transform: rotate(180deg);
      right: 0;
      opacity: 0;
    }

    .Text {
      font-size: 1.125rem;
    }
  }

  &:hover {
    .Icon {
      opacity: 1;
      svg {
        path {
          fill: ${({ theme }) => theme.farmProject.green};
        }
      }
    }

    .Text {
      color: ${({ theme }) => theme.farmProject.green};
    }
  }

  ${applyStyleModifiers(modifiers)};
`;

export const ButtonLabelWrapper = styled.div.attrs({
  className: 'ButtonLabelWrapper',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .Icon {
    width: 3rem;
    height: 3rem;
    margin-bottom: 0.5rem;

    svg {
      width: 3rem;
      height: 3rem;
    }
  }

  @media ${breakPoints.large_break} {
    flex-direction: row;

    .Icon {
      margin-bottom: 0;
    }
  }
`;
