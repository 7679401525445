import React from 'react';
import PropTypes from 'prop-types';

import { AssemblyGuideStyled } from './AssemblyGuide.styled';
import sectionsRenderer from './SectionsRenderer';

import { SectionProvider } from 'components';

import { useStopLoading } from 'utils/hooks';

const AssemblyGuide = ({ content }) => {
  useStopLoading(true);

  const fields = content.fields;

  return (
    <AssemblyGuideStyled>
      <SectionProvider sectionContent={fields.sections} customSectionMap={sectionsRenderer} />
    </AssemblyGuideStyled>
  );
};

AssemblyGuide.propTypes = {
  content: PropTypes.object,
};

export default AssemblyGuide;
