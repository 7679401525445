import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';

import { SliderSectionCustom } from './SliderSection.styled';

const SlderSection = ({ title, body, contentBlock }) => {
  const imageData = graphql`
    query {
      contentfulSectionBlocks(contentful_id: { eq: "50u58QpV0q5t80i78581G0" }) {
        contentful_id
        percentages {
          ... on ContentfulSectionBlocks {
            contentful_id
            mediaBackground {
              description
              gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 70)
            }
          }
        }
      }
    }
  `;

  return (
    <StaticQuery
      query={imageData}
      render={(data) => {
        return (
          <SliderSectionCustom
            contentBlock={contentBlock}
            gatsbyImages={data?.contentfulSectionBlocks?.percentages}
            title={title}
            body={body}
          />
        );
      }}
    />
  );
};

SlderSection.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  contentBlock: PropTypes.object,
};

export default SlderSection;
