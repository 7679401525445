import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const FarmstandProductStyled = styled.div.attrs({
  className: 'FarmstandProductStyled',
})`
  .MainContent {
    .PressReviewSlider {
      .PressSliderWrapper {
        max-width: calc(100% - 4rem);
        margin: auto;
      }
    }
  }

  .PressReviewSlider {
    z-index: 10;
    position: relative;
  }

  .ProductInformation .PromoToggleStyled {
    margin-bottom: 1.25rem;
  }

  .ReviewsAndQuestions .ContentSection {
    padding: 1rem 2rem;
  }

  @media ${breakPoints.medium_break} {
    .MainContent {
      .PressReviewSlider {
        .PressSliderWrapper {
          max-width: calc(100% - 8rem);
          margin: auto;
        }
      }
    }
  }
`;

export const HeroWrapper = styled.div.attrs({
  className: 'HeroWrapper',
})``;
