import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const SeedlingLandingStyled = styled.div.attrs({
  className: 'SeedlingLandingStyled',
})`
  position: relative;

  .ContentSection {
    background-color: white;

    .SectionInner {
      max-width: 33.75rem;
      margin: auto;
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      .SectionInner {
        max-width: 62rem;
        margin: auto;
      }
    }
  }
`;

export const MessageWrapper = styled.div.attrs({
  className: 'MessageWrapper',
})`
  width: 100%;
  box-sizing: border-box;
  padding: 0 2rem;

  .SlimBanner {
    width: fit-content;
    padding: 0.5rem 1.25rem;
    max-width: 33rem;
    margin: auto;
  }
`;
