import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const SandboxStyled = styled.div.attrs({
  className: 'Homepage',
})`
  margin: auto;

  @media ${breakPoints.medium_break} {
    .SectionInner {
      max-width: 33.5rem;
      margin: auto;
    }
  }

  @media ${breakPoints.large_break} {
    .SectionInner {
      max-width: 62rem;
    }
  }
`;

export const ContentWrapper = styled.div.attrs({
  className: 'ContentWrapper',
})``;
