/* eslint-disable react/prop-types, react/display-name */
import React from 'react';

import AssemblyHero from './components/assembly-hero/AssemblyHero';
import AssemblySteps from './components/assembly-steps/AssemblySteps';
import BlogSection from './components/blog-section/BlogSection';

const sections = {
  HERO: '7yCw8aITQjPobctEwFmG9M',
  ASSEMBLY_CARDS: '6ZbzyZpJtrUGDDbkCFVe8g',
  BLOG_SECTION: '1gFiIggpIOF86K1uKH11t0',
};

const sectionsRenderer = {
  [sections.HERO]: ({ title, body, contentBlock, callToAction }) => (
    <AssemblyHero
      key={sections.HERO}
      heroTitle={title}
      heroText={body}
      heroImage={contentBlock?.fields?.mediaContent?.fields?.file?.url}
      heroImageAlt={contentBlock?.fields?.mediaContent?.fields?.description}
      ctaLabel={callToAction?.fields?.linkText}
      ctaUrl={callToAction?.fields?.linkUrl}
    />
  ),
  [sections.ASSEMBLY_CARDS]: ({ percentages }) => <AssemblySteps key={sections.ASSEMBLY_CARDS} stepsData={percentages} />,
  [sections.BLOG_SECTION]: ({ percentages, title }) => (
    <BlogSection key={sections.BLOG_SECTION} title={title} blogPostsData={percentages} />
  ),
};

export default sectionsRenderer;
