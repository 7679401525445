import React from 'react';
import PropTypes from 'prop-types';

import { useStopLoading } from 'utils/hooks';

import { SectionProvider } from 'components';
import { SandboxStyled } from './Sandbox.styled';

const Sandbox = ({ content }) => {
  useStopLoading(true);

  const fields = content.fields;

  return (
    <SandboxStyled>
      <SectionProvider sectionContent={fields.sections} />
    </SandboxStyled>
  );
};

Sandbox.propTypes = {
  content: PropTypes.shape({ fields: PropTypes.object, sys: PropTypes.object }),
};

export default Sandbox;
