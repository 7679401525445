import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const ReturnLandingStyled = styled.div.attrs({
  className: 'ReturnLandingStyled',
})`
  .ModalVideoWrapper {
    .ComponentModal {
      .ModalWrapper {
        .modal__Content {
          button {
            right: 3.5%;
          }
        }
      }
    }

    @media ${breakPoints.large_break} {
      > .ComponentModal {
        .ModalWrapper {
          .modal__Content {
            button {
              right: 4%;
            }
          }
        }
      }
    }
  }
`;
