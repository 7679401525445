import React from 'react';
import { useStopLoading, useCatalog } from 'utils/hooks';
import PropTypes from 'prop-types';

import { ReviewsAndQuestions, PromoBanner, SectionProvider } from 'components';

import { FarmstandProductStyled } from './FarmstandProduct.styled';

import pageSpecificMap from './SectionsRenderer';
import { YOTPO_FARMSTAND_ID } from 'reduxState/userGenerated/userGenerated';

const FarmstandProduct = ({ content, farmSize }) => {
  const fields = content.fields;

  useCatalog();
  useStopLoading(true);

  return (
    <FarmstandProductStyled>
      <PromoBanner />
      <SectionProvider sectionContent={fields.sections} customSectionMap={pageSpecificMap} farmSizeFromUrl={farmSize} />
      <ReviewsAndQuestions productId={YOTPO_FARMSTAND_ID} />
    </FarmstandProductStyled>
  );
};

FarmstandProduct.propTypes = { content: PropTypes.object, farmSize: PropTypes.string };

export default FarmstandProduct;
