import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const BlogSectionStyled = styled.div.attrs({
  className: 'BlogSectionStyled',
})`
  padding: 3rem 0;
  background-color: ${({ theme }) => theme.farmProject.gray_2};

  .ContentSection {
    background-color: transparent;
    padding: 1rem;

    .SectionInner {
      max-width: 28rem;
      margin: auto;
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;
      align-items: center;
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      .SectionInner {
        flex-direction: row;
        flex-wrap: wrap;
        max-width: 37rem;
        align-items: stretch;

        .BlogCardStyled {
          width: 48%;
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      padding: 2rem;

      .SectionInner {
        max-width: 61rem;

        .BlogCardStyled {
          width: 31%;
        }
      }
    }
  }
`;

export const PostList = styled.div.attrs({
  className: 'PostList',
})`
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;
