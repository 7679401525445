import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

import { SquaredButton } from 'elements';
import { HomeHeroBanner } from './HeroBanner.styled';

import breakPoints from 'theme/Media';

const HeroBanner = ({ title, body, callToAction, modifiers, imageRouteLink, isTextInverted }) => {
  const imageData = graphql`
    query {
      contentfulSectionBlocks(contentful_id: { eq: "5UjRbxO4vacnUdkUSq0fhG" }) {
        mediaBackground {
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 70)
        }
      }
    }
  `;
  const actionLabel = callToAction?.fields?.linkText || callToAction?.fields?.text;
  const actionHref = callToAction?.fields?.linkUrl || callToAction?.fields?.url;

  return (
    <StaticQuery
      query={imageData}
      render={(data) => {
        const heroImages = data?.contentfulSectionBlocks?.mediaBackground?.map?.((image, index) => {
          return {
            image: image?.gatsbyImageData,
            alt: image?.description,
            media: index === 0 ? breakPoints.large_break : index === 1 ? breakPoints.medium_break : null,
            isDefault: index === 2,
          };
        });
        return (
          <HomeHeroBanner
            modifiers={modifiers}
            heroTitle={title}
            heroText={body}
            heroImages={heroImages}
            imageRouteLink={imageRouteLink}
            isTextInverted={isTextInverted}
            eventData={{ action: `IMAGE - ${actionLabel}` }}
          >
            <SquaredButton
              label={actionLabel}
              href={actionHref}
              modifiers={[isTextInverted && 'secondary']}
              eventData={{ action: `${actionLabel}` }}
            />
          </HomeHeroBanner>
        );
      }}
    />
  );
};

HeroBanner.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  callToAction: PropTypes.object,
  imageRouteLink: PropTypes.string,
  modifiers: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  isTextInverted: PropTypes.bool,
};

HeroBanner.defaultProps = {
  modifiers: [],
  isTextInverted: false,
};

export default HeroBanner;
