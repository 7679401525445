import React from 'react';
import { useEffect, useRef } from 'react';

const useInfiniteScroll = ({ onEndReached }) => {
  const lastY = useRef(0);
  const anchorRef = useRef(null);
  const Anchor = () => <div ref={anchorRef} />;

  const handleObserver = (entities) => {
    const y = entities[0].boundingClientRect.y;
    if (lastY.current > y) onEndReached?.();
    lastY.current = y;
  };
  const options = { root: null, rootMargin: '0px', threshold: 1.0 };
  const observer = typeof window !== 'undefined' && IntersectionObserver ? new IntersectionObserver(handleObserver, options) : null;

  useEffect(() => {
    if (anchorRef.current) {
      observer?.observe?.(anchorRef.current);
    }

    return () => observer?.disconnect?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onEndReached]);

  return { Anchor };
};

export default useInfiniteScroll;
