import React from 'react';
import PropTypes from 'prop-types';

import { ReturnLandingStyled } from './ReturnLanding.styled';
import { pageSpecificMap } from './SectionsRenderer';

import { useStopLoading } from 'utils/hooks';
import { SectionProvider } from 'components';

const ReturnLanding = ({ content }) => {
  const fields = content.fields;
  useStopLoading(true);

  return (
    <ReturnLandingStyled>
      <SectionProvider sectionContent={fields.sections} customSectionMap={pageSpecificMap} />
    </ReturnLandingStyled>
  );
};

ReturnLanding.propTypes = {
  content: PropTypes.object,
};

export default ReturnLanding;
