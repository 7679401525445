import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _throttle from 'lodash/throttle';
import _sortBy from 'lodash/sortBy';
import _flatten from 'lodash/flatten';
import { useLocation, navigate } from '@reach/router';
import queryString from 'query-string';
import PropTypes from 'prop-types';

import { SeedlingVote, ContentSection, Loading } from 'components';
import { Text, GatsbyImage } from 'elements';
import SeedlingsSearch from './seedlings-search/SeedlingsSearch';
import SeedlingsFilter from './seedlings-filter/SeedlingsFilter';

import { clearSeedlingsLPField, updateSeedlingsLPField } from 'reduxState/globalUI';
import { useCatalog, useStopLoading } from 'utils/hooks';
import itemAvailabilities from 'constants/itemAvailabilities';
import paths from 'constants/paths';
import scrollIntoView from 'utils/scrollIntoView';
import { decodeSku } from 'utils/string-utils';

import { FiltersSortingWrapper, SeedlingListWrapper, SeedlingList, SeedlingItem, LinkSeedlink } from './SeedlingsList.styled';

import useInfiniteScroll from 'utils/hooks/useInfiniteScroll';
import { fetchPreferences, getHasFetchedPreferences } from 'reduxState/userPreferences';
import { getLoginState } from 'reduxState/user';

const PAGE_SIZE = 24;

const SeedlingLP = ({ seedlingsContent = {} }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const searchTerm = useSelector(({ globalUI }) => globalUI?.seedlingsLandingPage?.search);
  const selectedFilters = useSelector(({ globalUI }) => globalUI?.seedlingsLandingPage?.filters);
  const seedlingContent = seedlingsContent?.allContentfulPlantType?.nodes || [];
  const hasFetchedSeedlingPreferences = useSelector(getHasFetchedPreferences);
  const catalog = useCatalog();
  const isLoggedIn = useSelector(getLoginState);

  useStopLoading(!!seedlingContent);

  /**
   *  page as state is a fix for material UI pagination footer, which has trouble updating
   *  the correct page when page is already in the url when the use access the seedlings page
   */
  const [page, setPage] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  useEffect(() => {
    setPage(parseInt(queryString.parse(location?.search)?.page) || 1);
  }, [location.search]);

  useEffect(() => {
    if (location?.search) scrollIntoView({ hash: '#FiltersWrapper' }, document.querySelector('#FiltersWrapper'));
  }, [location?.search]);

  useEffect(() => {
    const locationSearch = queryString.parse(location?.search);

    if (locationSearch.availability && locationSearch.availability === 'limited_release') {
      const availabilityFilters = selectedFilters.availability;
      const updatedFilters = { ...selectedFilters, ['availability']: [...availabilityFilters, 'Limited Release'] };

      dispatch(updateSeedlingsLPField({ key: 'filters', value: updatedFilters }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoggedIn && !hasFetchedSeedlingPreferences) {
      dispatch(fetchPreferences());
    }
  }, [isLoggedIn, dispatch, hasFetchedSeedlingPreferences]);

  const mapAvailability = (catalogSeedling) => {
    if (
      catalogSeedling.availability === itemAvailabilities.OUT_OF_SEASON &&
      !catalogSeedling.inStockDate &&
      !catalogSeedling.inSeasonDate
    ) {
      return 'Out of Season';
    }
    if (catalogSeedling.availability === itemAvailabilities.AVAILABLE || !catalogSeedling.inStockDate) {
      return 'In-Stock';
    }
  };

  /* HEADS UP
      AND filters across filter category
      OR filters within category
  */
  const allSeeds = seedlingContent;
  let filteredSeedlings = allSeeds;
  if (selectedFilters.type.length) {
    filteredSeedlings = filteredSeedlings.filter((seedling) => {
      return selectedFilters.type.includes(seedling?.category);
    });
  }

  if (selectedFilters.availability.length) {
    filteredSeedlings = filteredSeedlings.filter((seedling) => {
      const catalogSeedling = catalog.seedlings?.[seedling?.contentful_id];
      // isLimitedRelease is marked in contentful, not in the catalog
      if (seedling.isLimitedRelease) {
        if (selectedFilters.availability.includes('In-Stock')) {
          return false;
        } else {
          return true;
        }
      }
      // if seedling is not found in catalog - assume its availability is "out of season"
      if (!catalogSeedling && selectedFilters.availability.includes('Out of Season')) {
        return true;
      }
      if (!catalogSeedling) {
        return false;
      } else {
        return selectedFilters.availability.includes(mapAvailability(catalogSeedling));
      }
    });
  }

  if (searchTerm) {
    filteredSeedlings = filteredSeedlings.filter((seedling) => {
      return seedling?.friendlyName?.toLowerCase?.()?.includes?.(searchTerm.toLowerCase());
    });
  }

  const pagedSeedlings = filteredSeedlings.slice(0, page * PAGE_SIZE);

  const onEndReached = () => {
    const isShowingAllSeedlings = pagedSeedlings.length >= filteredSeedlings.length;
    if (isLoadingMore || isShowingAllSeedlings) return;

    setIsLoadingMore(true);
    setTimeout(() => {
      setIsLoadingMore(false);
      setPage((currentPage) => currentPage + 1);
    }, 1000);
  };

  const { Anchor } = useInfiniteScroll({ onEndReached });

  const handleReset = () => {
    dispatch(clearSeedlingsLPField('filters'));
  };

  const handleSeedlingClick = (seedling) => () => {
    navigate(`${paths.SEEDLINGS}/${seedling?.slug}`);
  };

  // handle 'enter/return' key hit
  const handleSeedlingSearch = (ev, value) => {
    if (value.friendlyName) {
      dispatch(updateSeedlingsLPField({ key: 'search', value: value?.friendlyName?.toLowerCase?.() }));
    }
  };

  const searchSeedlings = (event, value) => {
    // handle return key hit
    if (!event || event.keyCode === 13) return;

    dispatch(updateSeedlingsLPField({ key: 'search', value: value.toLowerCase() }));
  };

  const throttledSearchSeedlings = _throttle(searchSeedlings, 300);

  const filterSeedlings = (event, category) => {
    // handle checkbox change
    const value = event.target.name;
    const isChecked = event.target.checked;

    // Ensures user is always on first page for filtering
    if (page !== 1 || !location?.search) {
      setPage(1);
    }

    if (isChecked) {
      const currentCategoryFilters = selectedFilters[category.toLowerCase()];
      const updatedFilters = { ...selectedFilters, [category.toLowerCase()]: [...currentCategoryFilters, value] };

      dispatch(updateSeedlingsLPField({ key: 'filters', value: updatedFilters }));
    } else {
      const updatedFilters = Object.keys(selectedFilters).reduce((acc, category) => {
        acc[category] = selectedFilters[category].filter((option) => option !== value);
        return acc;
      }, {});

      dispatch(updateSeedlingsLPField({ key: 'filters', value: updatedFilters }));
    }
  };

  const searchOptions = () => {
    return _sortBy(allSeeds, [(seedling) => seedling?.category, (seedling) => seedling?.friendlyName]);
  };

  return (
    <>
      <FiltersSortingWrapper id='FiltersWrapper'>
        <SeedlingsSearch
          options={searchOptions()}
          onInputChange={throttledSearchSeedlings}
          onChange={handleSeedlingSearch}
          value={searchTerm}
        />
        <SeedlingsFilter onChange={filterSeedlings} selectedFilters={_flatten(Object.values(selectedFilters))} onReset={handleReset} />
      </FiltersSortingWrapper>
      <div data-nw='seedlings' />
      <SeedlingListWrapper>
        <ContentSection modifiers='leftText'>
          <SeedlingList>
            {pagedSeedlings.map((seedling) => {
              const friendlyName = seedling?.friendlyName;
              const listImg = seedling?.listingImage?.gatsbyImageData;
              return (
                <SeedlingItem key={seedling?.contentful_id}>
                  <LinkSeedlink onClick={handleSeedlingClick(seedling)}>
                    <GatsbyImage key='thumb' image={listImg} alt={friendlyName} />
                    <GatsbyImage key='thumb-hover' image={seedling?.listingImageHover?.gatsbyImageData} alt={friendlyName} />
                  </LinkSeedlink>
                  <Text modifiers={['brandFont', 'preWrap', 'noLineHeight']} content={friendlyName ? friendlyName : seedling?.name} />
                  <SeedlingVote itemId={decodeSku(seedling.contentful_id)} />
                </SeedlingItem>
              );
            })}
          </SeedlingList>
          {!pagedSeedlings.length && <Text content={'There are no results that match this search criteria.'} />}
          <Anchor />
          {isLoadingMore && <Loading />}
        </ContentSection>
      </SeedlingListWrapper>
    </>
  );
};

SeedlingLP.propTypes = {
  location: PropTypes.object,
  seedlingsContent: PropTypes.object,
};

export default SeedlingLP;
