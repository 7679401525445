import React from 'react';
import PropTypes from 'prop-types';

import { ContentSection } from 'components';
import { Title, ButtonText } from 'elements';

import { BlogSectionStyled, PostList } from './BlogSection.styled';
import BlogCard from '../blog-card/BlogCard';

const BlogSection = ({ blogPostsData }) => {
  const renderPost = (item) => {
    const { slug, title, postCategories, listingDescription, listingImage } = item.fields;
    const postCategory = postCategories?.[0]?.fields;

    return (
      <BlogCard
        key={slug}
        slug={slug}
        catSlug={postCategory?.slug}
        postCategory={postCategory?.title}
        postTitle={title}
        postText={listingDescription}
        picture={listingImage?.fields?.file?.url}
        alt={listingImage?.fields?.description}
      />
    );
  };

  return (
    <BlogSectionStyled>
      <Title as='h3' modifiers={['center', 'secondarySmall']} content='Discover More' />
      <PostList>
        <ContentSection>{blogPostsData?.map?.(renderPost)}</ContentSection>
        <ButtonText modifiers={['secondary', 'underlined']} label='LOAD MORE' />
      </PostList>
    </BlogSectionStyled>
  );
};

BlogSection.propTypes = {
  blogPostsData: PropTypes.array,
};

export default BlogSection;
