import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';

import { FarmGrow } from 'components';

const FarmstandGrowSection = ({ name, title, body, callToAction }) => {
  const imageData = graphql`
    query {
      contentfulSectionStandard(contentful_id: { eq: "1BLGRAfaUyE5g2AB6he2fs" }) {
        mediaContent {
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 70)
        }
      }
    }
  `;

  return (
    <StaticQuery
      query={imageData}
      render={(data) => {
        return (
          <FarmGrow
            name={name}
            title={title}
            body={body}
            callToAction={callToAction}
            gatsbyImageData={data?.contentfulSectionStandard?.mediaContent?.gatsbyImageData}
            gatsbyImageDataAlt={data?.contentfulSectionStandard?.mediaContent?.description}
          />
        );
      }}
    />
  );
};

FarmstandGrowSection.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  callToAction: PropTypes.object,
};

export default FarmstandGrowSection;
