import React from 'react'
import { Title, Text } from 'elements'
import { ContentSection } from 'components'
import { PageTopInfo } from './SeedlingsTitle.styled'

const SeedlingsTitle = ({ title, body }) => (
  <PageTopInfo>
    <ContentSection>
      <Title modifiers='tertiarySmall' content={title} as='h2' />
      <Text modifiers='lineHeightSmall' content={body} isHTML />
    </ContentSection>
  </PageTopInfo>
)

export default SeedlingsTitle