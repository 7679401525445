import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import WidthProvider from 'components/deprecated/WidthProvider';
import { Card } from 'components';
import { Text, Title, ButtonText, GatsbyImageLink } from 'elements';
import { RecipesInner } from './RecipeLanding.styled';

import paths from 'constants/paths';
import { useBreakpoint, usePaging, useStopLoading } from 'utils/hooks';

const RecipeLanding = ({ content, recipeContent }) => {
  const size = useBreakpoint();
  const pageSize = size === 'SM' || size === 'MD' ? 6 : 12;
  const recipes = recipeContent.allContentfulRecipe.nodes;
  const { pageCount, handleShowMore } = usePaging(pageSize, recipes ? recipes.length : 100);
  const isLoading = _isEmpty(content) || _isEmpty(recipes);
  useStopLoading(!isLoading);

  if (isLoading) return null;

  const pagedRecipes = recipes.slice(0, pageCount * pageSize);

  const getRecipes = () => {
    return pagedRecipes.map((recipe) => {
      const totalTime = _get(recipe, 'prepTime', 0) + _get(recipe, 'cookTime', 0);

      return (
        <Card key={recipe.sys.id} modifiers='fullBleed'>
          <div className='lg-media-wrapper'>
            <GatsbyImageLink image={recipe?.mainImage?.gatsbyImageData} imageRouteLink={`${paths.RECIPES}/${recipe.slug}`} nativeWidth={600} />
          </div>
          <Link to={`${paths.RECIPES}/${recipe.slug}`}>
            <Text modifiers={['small', 'brandColor', 'brandFont']} content={totalTime ? `${totalTime} MIN` : ''}></Text>
            {recipe.title && <Title modifiers='tertiaryXSmall' content={recipe.title.toUpperCase()}></Title>}
          </Link>
        </Card>
      );
    });
  };

  return (
    <>
      <div className='lg-recipes lg-page'>
        <Title modifiers={{ MD: 'secondary', SM: 'secondarySmall' }} content='OUR RECIPES' size={size}></Title>
        <RecipesInner>{getRecipes()}</RecipesInner>

        {pagedRecipes.length < recipes.length && (
          <ButtonText modifiers={['secondary', 'underlined']} label='LOAD MORE' onClick={handleShowMore} />
        )}
      </div>
    </>
  );
};

RecipeLanding.defaultProps = {
  content: [],
  metaContent: [],
};

RecipeLanding.propTypes = {
  location: PropTypes.object.isRequired,
  content: PropTypes.array,
  metaContent: PropTypes.array, // used for filters - coming soon KMS 4/9
};

export default WidthProvider(RecipeLanding);
