import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Text, Title, SquaredButton, Icon } from 'elements';
import { ContentSection } from 'components';
import { VideoModal } from 'components/modal';

import {
  ReturnInfoStyled,
  CallToActionWrapper,
  ButtonGroup,
  InfoTextWrapper,
  InfoInner,
  CategoryButtons,
  CategoryButton,
  CategoryWrapper,
  ButtonLabelWrapper,
} from './ReturnInfo.styled';

import Farmstand from 'elements/icon/assets/farmstand-icon.svg';
import GlowRings from 'elements/icon/assets/glowrings.svg';
import Extensions from 'elements/icon/assets/farmstand-levels.svg';
import Chevron from 'elements/icon/assets/chevron-thin.svg';
import PlayButton from 'elements/icon/assets/play-filled-icon.svg';
import Seedling from 'elements/icon/assets/seedling-new.svg';
import Dolly from 'elements/icon/assets/dolly-icon.svg';

const FARMSTAND_TAB_ID = '7mVEG12qerOozRIUcDVdIB';
const GLOWRING_TAB_ID = '6IGr013zh9O8TLuYl9RRLN';
const EXTENSION_TAB_ID = 'uyf5LByzwWNK7AYvz4VAs';
const SEEDLINGS_TAB_ID = '1MWxt0g6LmIJzZIKW6GRxq';
const CONTACT_US_CTA_ID = '1y0udzXZDLeWC3uM1QxOBo';
const DOLLY_TAB_ID = '7xVbLhF15j70JSWkrlMQbK';

const mapIconToTab = {
  [FARMSTAND_TAB_ID]: <Farmstand />,
  [GLOWRING_TAB_ID]: <GlowRings />,
  [EXTENSION_TAB_ID]: <Extensions />,
  [DOLLY_TAB_ID]: <Dolly />,
  [SEEDLINGS_TAB_ID]: <Seedling />,
};

const ReturnInfo = ({ title, productsTabsData, ctas = [], titleContactUs }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const selectedProduct = productsTabsData[selectedTab];
  const selectedCTAs = selectedProduct?.additionalButtons || [];
  const contactUsCTA = ctas.find(({ sys }) => sys.id === CONTACT_US_CTA_ID).fields;
  const [instructionCTA, videoCTA] = selectedCTAs.map(({ fields }) => fields);

  const onOpenModal = () => setIsModalOpen(true);

  const onCloseModal = () => setIsModalOpen(false);

  const onSelectTab = (newTabIndex) => () => setSelectedTab(newTabIndex);

  const renderProductTab = ({ id, headline }, index) => {
    const isSelected = index === selectedTab;
    return (
      <CategoryButton key={id} modifiers={[isSelected && 'selected']} onClick={onSelectTab(index)}>
        <ButtonLabelWrapper>
          <Icon>{mapIconToTab[id]}</Icon>
          <Text modifiers={['brandFont']} content={headline} />
        </ButtonLabelWrapper>
        <Icon>
          <Chevron />
        </Icon>
      </CategoryButton>
    );
  };

  const getVideoIframe = (videoUri) => {
    return `
      <iframe
        width='560'
        height='315'
        src='${videoUri}'
        title='LG player'
        frameborder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
        allowfullscreen
      />
    `;
  };

  return (
    <ReturnInfoStyled>
      <ContentSection modifiers={['leftText']}>
        <CategoryWrapper>
          <Title as='h2' modifiers={['xSmall', 'letterSpaceSmall', 'semiBold', 'left']} content={title} />
          <CategoryButtons>{productsTabsData.map(renderProductTab)}</CategoryButtons>
        </CategoryWrapper>
        <InfoInner>
          <InfoTextWrapper>
            <Title as='h2' modifiers={['xSmall', 'letterSpaceSmall', 'semiBold', 'left']} content={selectedProduct?.title} />
            <Text as='span' modifiers={['left']} content={selectedProduct?.body} isHTML />
          </InfoTextWrapper>
          {(instructionCTA || videoCTA) && (
            <CallToActionWrapper>
              {!!selectedProduct?.body2 && (
                <Title as='h2' modifiers={['xSmall', 'letterSpaceSmall', 'semiBold']} content={selectedProduct.body2} />
              )}
              <ButtonGroup>
                {!!instructionCTA?.linkText && (
                  <SquaredButton
                    modifiers={['tertiary']}
                    label={instructionCTA.linkText}
                    href={instructionCTA.linkUrl}
                    target={instructionCTA.target}
                    as='a'
                  />
                )}
                {instructionCTA && videoCTA && <Text as='span' content='or' />}
                {!!videoCTA?.linkText && (
                  <SquaredButton modifiers={['lined']} label={videoCTA.linkText} onClick={onOpenModal}>
                    <Icon>
                      <PlayButton />
                    </Icon>
                  </SquaredButton>
                )}
              </ButtonGroup>
            </CallToActionWrapper>
          )}
          <CallToActionWrapper>
            <Title as='h2' modifiers={['xSmall', 'letterSpaceSmall', 'semiBold']} content={titleContactUs} />
            <ButtonGroup>
              <SquaredButton label={contactUsCTA.linkText} href={contactUsCTA.linkUrl} as='a' />
            </ButtonGroup>
          </CallToActionWrapper>
        </InfoInner>
      </ContentSection>
      <VideoModal show={isModalOpen} onCloseAction={onCloseModal} embedCode={getVideoIframe(videoCTA?.linkUrl)} />
    </ReturnInfoStyled>
  );
};

ReturnInfo.propTypes = {
  title: PropTypes.string,
  productsTabsData: PropTypes.arrayOf(PropTypes.object),
  ctas: PropTypes.arrayOf(PropTypes.object),
  titleContactUs: PropTypes.string,
};

export default ReturnInfo;
