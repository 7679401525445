import styled from 'styled-components/macro';

import { FarmStandTableSection } from '../farm-table-section/FarmTableSection.styled';
import breakPoints from 'theme/Media';

export const ZooeyHarvestPlan = styled(FarmStandTableSection).attrs({
  className: 'ZooeyHarvestPlan',
})`
  .ContentSection {
    background-color: ${({ theme }) => theme.farmProject.gray_2};

    .SectionInner {
      .Link {
        position: relative;

        .Image {
          margin-bottom: 2rem;
        }
      }

      .ContentWrapper {
        .Title {
          max-width: 100%;
        }
      }
    }
  }

  @media ${breakPoints.medium_break} {
    .ContentSection {
      .SectionInner {
        .Link {
          .Image {
            margin-bottom: 4rem;
          }
        }

        .ContentWrapper {
          .Title {
            font-size: 3rem;
            max-width: 20rem;
            margin-bottom: 1.5rem;
          }

          .Text {
            max-width: 28rem;
            margin-left: 0;
          }

          .Button {
            margin-top: 3rem;
            max-width: 17rem;
          }
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      .SectionInner {
        flex-direction: row-reverse;

        .Link {
          .Image {
            margin-bottom: 0;
          }
        }

        .ContentWrapper {
          .Title {
            font-size: 3.75rem;
            max-width: 100%;
          }
        }
      }
    }
  }
`;
