import React from 'react';
import PropTypes from 'prop-types';
import { graphql, StaticQuery } from 'gatsby';

import { SquaredButton, Text, Title, GatsbyImageLink } from 'elements';
import { ContentSection } from 'components';

import { ContentWrapper } from 'staticPages/home/Home.styled';
import { PreSproutedSection } from './PreSprouted.styled';

const PreSprouted = ({ title, body, callToAction }) => {
  const imageData = graphql`
    query {
      contentfulSectionStandard(contentful_id: { eq: "fURedAq9ChuDtJPr77LRs" }) {
        mediaContent {
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 70)
        }
      }
    }
  `;
  const ctaLabel = callToAction?.fields?.linkText || callToAction?.fields?.text;
  const ctaLink = callToAction?.fields?.linkUrl || callToAction?.fields?.url;

  return (
    <StaticQuery
      query={imageData}
      render={(data) => {
        return (
          <React.Fragment>
            <PreSproutedSection>
              <ContentSection modifiers={['leftText']} animation='crossFade'>
                <GatsbyImageLink
                  modifiers='relativePosition'
                  image={data?.contentfulSectionStandard?.mediaContent?.gatsbyImageData}
                  alt={data?.contentfulSectionStandard?.mediaContent?.description}
                  imageRouteLink={callToAction?.fields?.linkUrl}
                  eventData={{ action: `IMAGE - ${ctaLabel}` }}
                />
                <ContentWrapper>
                  <Title content={title} modifiers='secondarySmall' as='h3' />
                  <Text content={body} isHTML />
                  <SquaredButton as='a' label={ctaLabel} href={ctaLink} eventData={{ action: ctaLabel }} />
                </ContentWrapper>
              </ContentSection>
            </PreSproutedSection>
          </React.Fragment>
        );
      }}
    />
  );
};

PreSprouted.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
  callToAction: PropTypes.object,
};

export default PreSprouted;
