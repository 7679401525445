import styled from 'styled-components/macro';
import breakPoints from 'theme/Media';

import { HeroSection } from 'components';

export const HomeHeroBanner = styled(HeroSection).attrs({
  className: 'HomeHeroBanner',
})`
  height: calc(100vh - 6.5rem);
  max-height: 46rem;
  padding: 2rem;
  justify-content: flex-start;
  position: relative;

  .HeroInner {
    .Title {
      font-size: 2.5rem;
      max-width: 20rem;
      padding-right: 0;
      margin: auto;
      margin-bottom: 0.5rem;
      font-weight: 500;
    }

    .Text {
      padding-right: 0;
      line-height: 1.875;
      max-width: 17rem;
      margin: auto;
    }

    .Button {
      width: 12rem;
      margin: auto;
      margin-top: 1.5rem;
      position: absolute;
      bottom: 4rem;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 2.5rem;
      width: 14rem;
    }

    .Image {
      img {
        top: 47%;
        width: auto;
        height: 100%;
      }
    }
  }

  @media ${breakPoints.medium_break} {
    height: 80rem;
    padding: 3rem;

    .HeroInner {
      max-width: 33.5rem;

      .Title {
        font-size: 3rem;
        max-width: 23rem;
        padding-right: 0;
        margin: auto;
      }

      .Text {
        padding: 0;
      }

      .Image {
        height: 100%;

        img {
          width: 110%;
          top: 50%;
        }
      }
    }
  }

  @media ${breakPoints.large_break} {
    justify-content: center;

    .HeroInner {
      max-width: 62rem;
      justify-content: center;

      .Title {
        max-width: 27rem;
        font-size: 3.5rem;
        text-align: left;
        margin: 0;
        margin-bottom: 0.5rem;
      }

      .Text {
        text-align: left;
        margin: 0;
        font-size: 1.5rem;
        max-width: 27rem;
        line-height: 1.5;
      }

      .Button {
        position: relative;
        top: auto;
        left: auto;
        transform: none;
        bottom: 0;
        margin: 0;
        margin-top: 1.5rem;
      }

      .Image {
        img {
          width: 120%;
          height: auto;
        }
      }
    }
  }

  @media ${breakPoints.xLarge_break} {
    .HeroInner {
      .Image {
        img {
          width: 110%;
          height: auto;
        }
      }
    }
  }
  @media ${breakPoints.xxLarge_break} {
    .HeroInner {
      .Image {
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
`;
