import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import { Icon } from 'elements';
import { SeedlingsSearchStyled } from './SeedlingsSearch.styled';
import SearchIcon from 'elements/icon/assets/search.svg';

const SeedlingsSearch = ({ options, onChange, onInputChange, value }) => {
  return (
    <SeedlingsSearchStyled>
      <Icon modifiers='darkGrayColor'>
        <SearchIcon />
      </Icon>
      <Autocomplete
        options={options}
        disableClearable
        getOptionLabel={(option) => option?.friendlyName || ''}
        freeSolo
        renderInput={(params) => <TextField {...params} name='test' placeholder='Search our seedlings' />}
        groupBy={(option) => option?.category}
        onChange={onChange}
        onInputChange={onInputChange}
        inputValue={value}
      />
    </SeedlingsSearchStyled>
  );
};

SeedlingsSearch.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  value: PropTypes.string,
};

export default SeedlingsSearch;
