import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import RecipeLanding from './RecipeLanding.js';

const recipesQuery = graphql`
  query {
    allContentfulRecipe {
      nodes {
        id
        contentful_id
        slug
        title
        cookTime
        prepTime
        publishDate
        sys {
          contentType {
            sys {
              id
            }
          }
        }
        mainImage {
          description
          gatsbyImageData(layout: FIXED, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 90, height: 200)
        }
      }
    }
  }
`;

const Recipes = ({ content }) => (
  <StaticQuery
    query={recipesQuery}
    render={(data) => {
      return <RecipeLanding recipeContent={data} content={content} />;
    }}
  />
);

export default Recipes;
