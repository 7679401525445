import styled from 'styled-components';
import { applyStyleModifiers } from 'styled-components-modifiers';
import breakPoints from 'theme/Media';

export const modifiers = {
  filled: () => ({
    styles: `
      &::after {
        display: block;
      }
    `,
  }),
  uncollapsed: () => ({
    styles: `
      .SelectedPreview {
        display: none;
      }
    `,
  }),
  clearAll: ({ theme }) => ({
    styles: `
      background-color: transparent;

      .CloseButtonTag {
        margin-right: 0;

        .Text {
          margin-left: 0.5rem;
        }
      }

    `,
  }),
};

export const SeedlingsFilterStyled = styled.div.attrs({
  className: 'SeedlingsFilterStyled',
})`
  width: 100%;
`;

export const FilterMobileCta = styled.div.attrs({
  className: 'FilterMobileCta',
})`
  width: 100%;
  max-width: 20rem;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${breakPoints.large_break} {
    display: none;
  }
`;

export const SideDrawerFilter = styled.div.attrs({
  className: 'SideDrawerFilter',
})`
  > div {
    .haderWrapperDrawer {
      .headerDrawer {
        padding: 1rem 0;
        border-bottom: solid 1px ${({ theme }) => theme.farmProject.dark_gray};

        button {
          .Icon {
            width: 1rem;
          }
        }
      }
      .headlineDrawer {
        font-size: 1.125rem;
        margin: auto;
      }
    }

    @media ${breakPoints.medium_break} {
      width: 64vw;
      max-width: 27rem;
    }
  }

  @media ${breakPoints.large_break} {
    display: none;
  }
`;

export const SideDrawerItems = styled.div.attrs({
  className: 'SideDrawerItems',
})``;

export const Item = styled.div.attrs({
  className: 'Item',
})`
  position: relative;
  border-bottom: solid 1px ${({ theme }) => theme.farmProject.green};

  &::after {
    content: '';
    display: none;
    width: 1rem;
    height: 3.25rem;
    background-color: ${({ theme }) => theme.farmProject.green};
    position: absolute;
    top: 0.75rem;
    left: -1.275rem;
    transform: translate(-50%, -50%);
  }

  ${applyStyleModifiers(modifiers)};
`;

export const SelectedPreview = styled.div.attrs({
  className: 'SelectedPreview',
})`
  position: absolute;
  width: 100%;
  transform: translate(-50%, -50%);
  left: 50%;
  top: auto;
  bottom: -0.125rem;
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.farmProject.gray_2};
  overflow: hidden;

  .Text {
    margin-right: 0.25rem;
    white-space: nowrap;
  }
`;

export const SideDrawerContent = styled.div.attrs({
  className: 'SideDrawerContent',
})`
  .AccordionSection {
    margin-top: 1.875rem;

    .AccordionTitleSection {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;
      position: relative;

      .AccordionTitle {
        font-family: ${({ theme }) => theme.farmProject.font_family};
        font-size: 1.125rem;
      }

      span {
        width: 1rem;
      }
    }

    .Accordion {
      margin-top: 0.875rem;
      margin-bottom: 1.25rem;
    }

    .lg-accordion__inner {
      .component__Checkbox {
        justify-content: flex-start;
        margin-bottom: 0.9rem;

        .checkbox__IconContainer {
          width: 1.25rem;
          height: 1.25rem;
          border: solid 1px ${({ theme }) => theme.farmProject.green};
        }
      }
    }
  }
`;

export const ButtonGroup = styled.div.attrs({
  className: 'ButtonGroup',
})`
  padding: 2.5rem 2rem;
  background-color: ${({ theme }) => theme.farmProject.gray_2};
  margin: 2rem 0 0;
  box-shadow: 2px -22px 30px -35px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  .Button {
    width: calc(50% - 0.5rem);
    border: solid 1px ${({ theme }) => theme.farmProject.dark_gray};
  }

  @media ${breakPoints.small_break} {
    padding: 2.5rem 2.25%;
    left: 0;
    transform: translate(0, -50%);
  }

  @media ${breakPoints.medium_break} {
    width: 59.5vw;
    max-width: 24.4rem;
  }
`;

export const DesktopFiltersWrapper = styled.div.attrs({
  className: 'DesktopFiltersWrapper',
})`
  max-width: 30rem;
  margin: auto;
  margin-top: 2rem;

  .FilterDropDownList {
    display: none;
  }

  .FilterTagList {
    display: flex;
  }

  @media ${breakPoints.large_break} {
    max-width: 45rem;
    display: block;

    .FilterDropDownList {
      display: flex;
    }
  }
`;

export const FilterDropDownsList = styled.ul.attrs({
  className: 'FilterDropDownList',
})`
  width: 100%;
  max-width: 44rem;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const FilterDropDown = styled.li.attrs({
  className: 'FilterDropDown',
})``;

export const ButtonDropDown = styled.button.attrs({
  className: 'ButtonDropDown',
  type: 'button',
})`
  display: flex;
  margin: auto 1rem;
  cursor: pointer;

  .Text {
    margin-right: 0.5rem;
  }

  .Icon {
    width: 1rem;
    height: 1rem;
    cursor: pointer;

    svg {
      transition: all ease-in-out 0.2ms;
    }
  }
`;

export const CheckboxFilterWrapper = styled.div.attrs({
  className: 'CheckboxFilterWrapper',
})`
  max-width: 45rem;
  margin: 1rem auto 2rem;
  display: none;
  justify-content: center;
  align-items: center;

  label {
    margin: 0 0.9rem;
    font-size: 0.875rem;
    font-weight: 400;

    .checkbox__IconContainer {
      border: solid 1px ${({ theme }) => theme.farmProject.green};
      flex-shrink: 0;
    }
  }

  @media ${breakPoints.large_break} {
    display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  }
`;

export const FilterTagList = styled.ul.attrs({
  className: 'FilterTagList',
})`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0 auto;
  box-sizing: border-box;
  overflow-x: auto;
  overflow-y: hidden;
  margin-top: 2rem;

  &::-webkit-scrollbar {
    height: 0;
  }

  @media ${breakPoints.large_break} {
    justify-content: center;
    overflow: hidden;
    flex-wrap: wrap;
    max-width: 35rem;
    justify-content: center;
  }
`;

export const FilterTag = styled.li.attrs({
  className: 'FilterTag',
})`
  display: flex;
  margin: 0.25rem 0.5rem;
  flex-shrink: 0;
`;

export const Tag = styled.div.attrs({
  className: 'Tag',
})`
  background-color: ${({ theme }) => theme.farmProject.gray_2};
  padding: 0.325rem 0.6rem;
  display: flex;
`;

export const CloseButtonTag = styled.button.attrs({
  className: 'CloseButtonTag',
})`
  margin-right: 0.5rem;
  display: flex;

  .Icon {
    width: 0.75rem;
    height: 0.75rem;
    cursor: pointer;
  }
`;
