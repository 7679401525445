import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';

import SideDrawer from 'components/deprecated/SideDrawer';
import AccordionSection from 'components/deprecated/elements/AccordionSection';
import { Text, Icon, Checkbox, SquaredButton } from 'elements';
import ArrowDown from 'elements/icon/assets/chevron.svg';
import Close from 'elements/icon/assets/close.svg';
import {
  SeedlingsFilterStyled,
  FilterMobileCta,
  SideDrawerFilter,
  SideDrawerContent,
  SideDrawerItems,
  Item,
  ButtonGroup,
  DesktopFiltersWrapper,
  SelectedPreview,
  FilterDropDownsList,
  FilterDropDown,
  ButtonDropDown,
  CheckboxFilterWrapper,
  FilterTagList,
  FilterTag,
  Tag,
  CloseButtonTag,
} from './SeedlingsFilter.styled';

const LABEL_UNAVAILABLE = 'Unavailable';
const LABEL_OUTOFSEASON = 'Out of Season';

const seedlingCategories = [
  {
    title: 'TYPE',
    options: ['Fruits & Vegetables', 'Herbs', 'Leafy Greens', 'Special Varieties'],
  },
  {
    title: 'AVAILABILITY',
    options: ['In-Stock', LABEL_OUTOFSEASON, 'Limited Release'],
  },
];

const getFormikInitialValues = (filters = []) =>
  seedlingCategories.reduce((acc, category) => {
    category.options.forEach((option) => {
      acc[option] = filters.includes(option);
    });
    return acc;
  }, {});

const SeedlingsFilter = ({ onChange, selectedFilters, onReset }) => {
  const [isSideDrawerOpen, setIsSideDrawerOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState('');
  const options = seedlingCategories.find((category) => category.title === openDropdown)?.options;

  const toggleSideDrawer = () => {
    setIsSideDrawerOpen(!isSideDrawerOpen);
  };

  const changeOpenDropdown = (value) => () => {
    let _value = value;

    // toggle open dropdown
    if (value === openDropdown) _value = '';

    setOpenDropdown(_value);
  };

  const handleRemoveTag = (value, setFieldValue) => () => {
    setFieldValue(value, false);
    onChange({ target: { name: value, checked: false } });
  };

  return (
    <SeedlingsFilterStyled>
      <FilterMobileCta>
        <SquaredButton label='FILTER BY' onClick={toggleSideDrawer} modifiers={['inverted', 'fluid']}></SquaredButton>
      </FilterMobileCta>
      <Formik initialValues={getFormikInitialValues(selectedFilters)} onReset={onReset}>
        {({ resetForm, setFieldValue }) => {
          return (
            <Form>
              <>
                <SideDrawerFilter>
                  <SideDrawer
                    key='filter-drawer'
                    closeModal={toggleSideDrawer}
                    headline='FILTER BY'
                    isOpen={isSideDrawerOpen}
                    modifiers='left'
                    useBackgroundTheme
                  >
                    <SideDrawerContent>
                      <SideDrawerItems>
                        {seedlingCategories.map((category) => {
                          const selectedCatFilters = category.options.filter((option) => selectedFilters.includes(option));
                          return (
                            <Item key={category.title} modifiers={selectedCatFilters.length && 'filled'}>
                              <AccordionSection
                                modifiers={selectedCatFilters.length && 'filled'}
                                title={category.title}
                                renderCustomTitle={(handleOpen, isOpen) => {
                                  return (
                                    <SelectedPreview>
                                      {!isOpen &&
                                        selectedCatFilters.map((value, idx) => (
                                          <Text
                                            key={value}
                                            modifiers={['brandSmall', 'small', 'darkGrayColor']}
                                            content={`${value === LABEL_OUTOFSEASON ? LABEL_UNAVAILABLE : value}${
                                              idx !== selectedCatFilters.length - 1 ? ', ' : ''
                                            }`}
                                          />
                                        ))}
                                    </SelectedPreview>
                                  );
                                }}
                              >
                                {category.options.map((option) => (
                                  <Field
                                    key={option}
                                    name={option}
                                    component={Checkbox}
                                    label={option === LABEL_OUTOFSEASON ? LABEL_UNAVAILABLE : option}
                                    onChange={(ev) => onChange(ev, category.title)}
                                    value={selectedFilters.includes(option)}
                                    checked={selectedFilters.includes(option)}
                                  />
                                ))}
                              </AccordionSection>
                            </Item>
                          );
                        })}
                      </SideDrawerItems>
                      {isSideDrawerOpen && (
                        <ButtonGroup>
                          <SquaredButton type='button' modifiers='inverted' label='CLEAR ALL' onClick={resetForm} />
                          <SquaredButton type='button' label={`APPLY (${selectedFilters.length})`} onClick={toggleSideDrawer} />
                        </ButtonGroup>
                      )}
                    </SideDrawerContent>
                  </SideDrawer>
                </SideDrawerFilter>

                <DesktopFiltersWrapper>
                  <FilterDropDownsList>
                    {seedlingCategories.map((category) => {
                      return (
                        <FilterDropDown key={category.title}>
                          <ButtonDropDown onClick={changeOpenDropdown(category.title)}>
                            <Text modifiers='assignature' content={category.title} />
                            <Icon modifiers={[openDropdown === category.title && 'rotate180', 'brandColor']}>
                              <ArrowDown />
                            </Icon>
                          </ButtonDropDown>
                        </FilterDropDown>
                      );
                    })}
                  </FilterDropDownsList>
                  <CheckboxFilterWrapper isVisible={!!openDropdown}>
                    {options?.map((option) => (
                      <Field
                        key={option}
                        name={option}
                        component={Checkbox}
                        label={option === LABEL_OUTOFSEASON ? LABEL_UNAVAILABLE : option}
                        onChange={(ev) => onChange(ev, openDropdown)}
                        value={selectedFilters.includes(option)}
                        checked={selectedFilters.includes(option)}
                      />
                    ))}
                  </CheckboxFilterWrapper>
                  <FilterTagList>
                    {selectedFilters.map((value) => {
                      return (
                        <FilterTag key={value}>
                          <Tag>
                            <CloseButtonTag onClick={handleRemoveTag(value, setFieldValue)}>
                              <Icon>
                                <Close />
                              </Icon>
                            </CloseButtonTag>
                            <Text modifiers={['brandTiny', 'small']} content={value === LABEL_OUTOFSEASON ? LABEL_UNAVAILABLE : value} />
                          </Tag>
                        </FilterTag>
                      );
                    })}
                    {selectedFilters.length > 1 && (
                      <FilterTag key='clear'>
                        <Tag>
                          <CloseButtonTag onClick={resetForm}>
                            <Icon>
                              <Close />
                            </Icon>
                          </CloseButtonTag>
                          <Text modifiers={['small', 'brandFont']} content='Clear All' />
                        </Tag>
                      </FilterTag>
                    )}
                  </FilterTagList>
                </DesktopFiltersWrapper>
              </>
            </Form>
          );
        }}
      </Formik>
    </SeedlingsFilterStyled>
  );
};

SeedlingsFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  selectedFilters: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default SeedlingsFilter;
