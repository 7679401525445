import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const FiltersSortingWrapper = styled.section.attrs({
  className: 'FiltersSortingWrapper',
})`
  padding: 0 2rem;

  span {
    display: block;
    width: 100%;
    text-align: center;
  }
`;

export const SeedlingListWrapper = styled.section.attrs({
  className: 'SeedlingListWrapper',
})`
  .ContentSection {
    padding-top: 3rem;
  }
`;

export const SeedlingList = styled.ul.attrs({
  className: 'SeedlingList',
})`
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  margin: auto;

  @media ${breakPoints.medium_break} {
    max-width: 33rem;
  }

  @media ${breakPoints.medium_break} {
    max-width: 53rem;
  }

  @media ${breakPoints.large_break} {
    max-width: 62rem;
  }
`;

export const SeedlingItem = styled.li.attrs({
  className: 'SeedlingItem',
})`
  min-width: 6rem;
  width: calc(50% - 1.25rem);
  margin: 0 0.5rem 1.5rem;

  .Text {
    height: 2.5rem; // allow for name wrap
  }

  @media ${breakPoints.medium_break} {
    width: 31%;
    margin: 0 0.25rem 1.5rem;
  }

  @media ${breakPoints.large_break} {
    width: 16rem;
    margin: 0 0.75rem 1.5rem;
    .Text {
      height: 1.5rem;
    }
  }
`;

export const LinkSeedlink = styled.a.attrs({
  className: 'LinkSeedlink',
})`
  width: 100%;
  height: 7rem;
  position: relative;
  display: block;
  margin-bottom: 0.5rem;

  .Image {
    transition: opacity 0.5s ease-in-out;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .Image + .Image {
    display: block;
    opacity: 0;
  }

  &:hover {
    .Image {
      opacity: 0;
    }

    .Image + .Image {
      opacity: 1;
    }
  }

  @media ${breakPoints.medium_break} {
    height: 12.5rem;
  }
`;
