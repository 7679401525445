import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import PropTypes from 'prop-types';

import { HowItsMade } from 'components';

import breakPoints from 'theme/Media';

const HowItsMadeSection = ({ title, percentages }) => {
  const imageData = graphql`
    query {
      contentfulSectionBlocks(contentful_id: { eq: "6XxdTTy6fo7uVgrh4z2ZG6" }) {
        mediaBackground {
          contentful_id
          description
          gatsbyImageData(layout: FULL_WIDTH, placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP], quality: 70)
        }
      }
    }
  `;

  return (
    <StaticQuery
      query={imageData}
      render={(data) => {
        const backgroundImages = data?.contentfulSectionBlocks?.mediaBackground
          ?.map?.((image, index) => {
            return {
              image: image?.gatsbyImageData,
              description: image?.description,
              media: index === 2 ? breakPoints.large_break : index === 1 ? breakPoints.medium_break : null,
              isDefault: index === 0,
              index,
            };
          })
          .sort((a, b) => b.index - a.index);
        return <HowItsMade title={title} percentages={percentages} breakPointImages={backgroundImages} />;
      }}
    />
  );
};

HowItsMadeSection.propTypes = {
  title: PropTypes.string,
  percentages: PropTypes.array,
};

export default HowItsMadeSection;
