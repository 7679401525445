import styled from 'styled-components';
import breakPoints from 'theme/Media';

export const AssemblyStepsStyled = styled.div.attrs({
  className: 'AssemblyStepsStyled',
})`
  .ContentSection {
    background-color: ${({ theme }) => theme.farmProject.white};
    padding: 0;

    .SectionInner {
      max-width: 33rem;
      margin: auto;

      > .Text {
        max-width: 30rem;
        margin: auto;
      }
    }
  }

  @media ${breakPoints.large_break} {
    .ContentSection {
      padding: 0 2rem;

      .SectionInner {
        max-width: 55rem;

        > .Title {
          font-size: 3rem;
        }
      }
    }
  }
`;

export const StepList = styled.div.attrs({
  className: 'StepList',
})`
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  .AssemblyCardStyled {
    &:nth-child(2) {
      .AssemblyInfo {
        .Title,
        .Text {
          padding-left: 7rem;
        }
      }

      .AssemblyImageWrapper {
        height: 25rem;
        width: 11rem;
        position: absolute;
        left: 2.75rem;
      }
    }

    &:nth-child(3) {
      .AssemblyImageWrapper {
        height: 24rem;
        width: 13rem;
      }
    }
  }

  @media ${breakPoints.large_break} {
    .AssemblyCardStyled {
      &:nth-child(2) {
        .AssemblyInfo {
          .Title,
          .Text {
            padding-left: 0;
          }
          padding-left: 13rem;
        }

        .AssemblyImageWrapper {
          height: 38rem;
          width: 17rem;
          left: 7.15rem;
        }
      }

      &:nth-child(3) {
        .AssemblyImageWrapper {
          height: 34rem;
          width: 19rem;
          right: -8rem;
        }
      }
    }
  }
`;
