/* eslint-disable react/prop-types, react/display-name */
import React from 'react';

import ReturnHero from './components/return-hero/ReturnHero';
import ReturnInfo from './components/return-info/ReturnInfo';

const sections = {
  HERO: '4BqxAfN6CStZoNbvXPBqZS',
  RETURN_INFO: '3zbL8VVWZlWGI0jqylNGKB',
};

export const pageSpecificMap = {
  [sections.HERO]: ({ title, body, contentBlock }) => (
    <ReturnHero
      key={sections.HERO}
      heroTitle={title}
      heroText={body}
      heroImage={contentBlock?.fields?.mediaContent?.fields?.file?.url}
      heroImageAlt={contentBlock?.fields?.mediaContent?.fields?.description}
    />
  ),
  [sections.RETURN_INFO]: ({ title, body, body2, percentages, additionalButtons }) => {
    const productsTabs = percentages?.map?.((p) => ({ ...p?.fields, id: p.sys.id })) || [];
    return (
      <ReturnInfo
        key={sections.RETURN_INFO}
        title={title}
        productsTabsData={productsTabs}
        titleAssembling={body}
        titleContactUs={body2}
        ctas={additionalButtons}
      />
    );
  },
};
